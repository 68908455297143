<template>
  <modal-pro></modal-pro>
  <section class="section-full banner">
    <div class="box">
      <img src="./../../assets/banner-lc.png" alt="banner-lc" class="banner-img"  />
      <div class="banner-sep"></div>
      <h1 v-html="$t('lc.title1')"></h1>
      <scroll-down></scroll-down>
    </div>
  </section>
  <product v-for="(item, idx) in products" :key="idx" :product="item"></product>
  <product-range product="LC"></product-range>
</template>

<script>
import ScrollDown from "./../../components/ScrollDown.vue";
import ProductRange from "./../../components/ProductsRange.vue";
import Product from "./../../components/Product.vue";
import ModalPro from "./../../components/ModalPro.vue";

export default {
  name: "LC",
  components : { ScrollDown, ProductRange, Product, ModalPro},
  computed : {
    products() {
      return  [
        {
          img : [
            require("@/assets/products/LC-cage.png")
          ],
          bg : require("@/assets/products/cedar_bg.jpg"),
          name : "<span class='colored'>C</span>edar & <span class='colored'>E</span>lm",
          special :  true,
          video : "https://youtu.be/ZB0GUEScHb4",
          bullets : [
            this.$t("cage.bullet1"),
            this.$t("cage.bullet2"),
            this.$t("cage.bullet3")
          ]
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">

h1 {
  color:#fff;
  font-size:30px;
  font-weight:400;
  text-align: center;
  line-height:1.5em;
  margin:0;
}

.banner img {
  width:800px;
  max-width: 90%;
}

@media screen and (max-width:768px) {
  h1 {
    font-size:20px;
  }
}
</style>